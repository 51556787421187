* {
  font-family: Arial, sans-serif;
}


body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #000;
}

.landing__top {
  background-color: #000;
  color: #fff;
  /* text-align: center; */
  padding: 10px 40px;
  font-size: 20px;
  /* position: fixed; */
  width: 100%;
  /* top: 0; */
  z-index: 9999;

  p {
    letter-spacing: -0.5px;
    font-weight: 500;
  }
}

.landing__top a {
  color: #f0db4f;
  text-decoration: none;
}
.top-main{
  position: fixed;
  top: 0;
  z-index: 9999;
  width: 100%;
}
.landing__header--scrolled {
  background-color: #fff;
  /* Background when scrolled */
  color: #fff;
  transition: background-color 0.6s ease;
}

.landing {
  /* background-image: url("./images/12-01.png"); */
  /* Replace with your actual background image */
  /* background-size: cover;
  background-position: center;
  color: #fff; */
  /* padding: 15px 20px; */
  /* text-align: center; */
  height: 100vh;
}

.landing__title-main {
  background-image: url("./images/animate-img.png");
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;

}

.landing__header {
  display: flex;
  justify-content: space-around;
  /* position: fixed; */
  /* top: 10%; */
  width: 100%;
  padding: 10px 0px 10px 0px;
  z-index: 99999;
  align-items: center;
}

.landing__logo {
  height: 50px;
  transition: filter 0.3s ease; /* Smooth transition */
  filter: brightness(0) saturate(100%) invert(1) sepia(100%) saturate(200%) hue-rotate(190deg) contrast(100%);

}

.landing__logo--scrolled {
  filter: brightness(1) saturate(100%) invert(0) sepia(100%) saturate(200%) hue-rotate(190deg) contrast(100%);
}

.landing__menu a {
  position: relative;
  text-decoration: none;
  /* Remove default underline */
  color: #fff;
  /* Default text color */
  font-size: 1rem;
  margin-right: 20px;
  display: inline-block;
  transition: color 0.3s ease;
  /* Smooth color transition */
}

.landing__menu a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px;
  /* Position underline slightly below text */
  width: 0;
  height: 2px;
  /* Thickness of the underline */
  background: #000;
  /* Color of the underline */
  transition: width 0.3s ease;
}

.landing__menu a:hover::after {
  width: 100%;
  /* Full width on hover */
}

.logo-swiper-img-div {
  width: 100%;

  img {
    object-fit: cover !important;
  }
}

.landing__menu a:hover {
  color: #007BFF;
  /* Optional: Change text color on hover */
}

.landing__menu--scrolled a {
  color: #000;

}

.landing__menu-cta {
  background-color: #fff;
  color: #000;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.landing__menu-cta--scrolled {
  background-color: #000;
  color: #fff;
}

.landing__content {
  position: relative; /* Content stays on top of the video */
  z-index: 1; /* Ensures content is above the video */
  color: white; /* Text color should contrast with the video */
  text-align: center;
}

.landing__title-center {
  font-size: 6rem;
  margin-bottom: 10px;
  margin: 0;
  letter-spacing: -6px;
  /* padding-left: 10%; */
  /* padding-top: 10%; */
  animation-duration: 2s;
  /* Adjust speed (e.g., 2s, 3s) */
}

.landing__title-left {
  font-size: 50px;
  margin-bottom: 10px;
  margin: 0;
  /* letter-spacing: -6px; */
  /* padding-left: 10%; */
  /* padding-top: 10%; */
  animation-duration: 2s;
  /* Adjust speed (e.g., 2s, 3s) */

  span{
    font-size: 30px;
  }
}

.landing__title-right {
  font-size: 50px;
  margin-bottom: 10px;
  margin: 0;
  /* letter-spacing: -6px; */
  /* padding-left: 10%; */
  /* padding-top: 10%; */
  animation-duration: 2s;
  span{
    font-size: 30px;
  }
 
}

.landing__divider {
  border: 1px solid white;
  margin: 20px auto;
  width: 50%;
}
.video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: inherit;
  overflow: hidden;
  z-index: -1; /* Video stays in the background */
}
.background-video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video covers the entire background */
}

.landing__subtitle {
  font-size: 30px;
  margin: 111px 0;
  font-weight: 600;
  text-align: center;
}

.landing-title-head-sec {
  /* padding-left: 50px; */
  display: flex;
  justify-content: center;
  gap: 100px;
  flex-wrap: wrap;
  align-items: end;
  padding-top: 15%;
}

.landing__cta {
  background-color: #fff;
  color: #000;
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 20px;
  position: relative;
  overflow: hidden;
  transition: color 0.3s ease;
  /* Smooth transition for text color */
}

.landing__cta::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #000, #555);
  /* Gradient for animated effect */
  transition: transform 0.4s ease;
  z-index: 0;
}

.landing__cta:hover::before {
  left: 0;
  transform: scaleX(1);
  transition: all 0.4s ease-in-out;
  /* Smooth animation */
}

.landing__cta:hover {
  color: #fff;
  /* Text color changes on hover */
}

.landing__cta span {
  position: relative;
  z-index: 1;
}



.landing__ctaa {
  background-color: #fff;
  color: #000;
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  margin-bottom: 80px;
  margin-top: 20px;
  position: relative;
  overflow: hidden;
  transition: color 0.3s ease;
  /* Smooth transition for text color */
  font-family: sans-serif !important;
}

.landing__ctaa::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #000, #555);
  /* Gradient for animated effect */
  transition: transform 0.4s ease;
  z-index: 0;
}

.landing__ctaa:hover::before {
  left: 0;
  transform: scaleX(1);
  transition: all 0.4s ease-in-out;
  /* Smooth animation */
}

.landing__ctaa:hover {
  color: #fff;
  /* Text color changes on hover */
}

.landing__ctaa span {
  position: relative;
  z-index: 1;
}

.landing__logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 45px;
  margin-top: 30px;
}

.landing-logos-main {
  padding: 40px;
  background: linear-gradient(120deg, #fff, #20bad1);
}

.landing__logos img {
  height: auto;
  width: 200px;
  position: relative;
  /* Required for the pseudo-element */
  z-index: 1;
  /* Keep image above the background */
  transition: transform 0.3s ease;
  /* Smooth zoom effect */
  border: 1px solid #8080806b;
}

.landing__logos img:hover {
  transform: scale(1.05);
  /* Slight zoom on hover */
  background: linear-gradient(120deg, #4e8694, #fff);
}

.landing__logos img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg, #ff7eb3, #ff758c);
  z-index: -1;
  /* Place the gradient behind the image */
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  opacity: 0;
  transform: scale(1.1);
  /* Slightly larger for smooth animation */
  border-radius: 10px;
  /* Optional: rounded background */
}

.landing__logos img:hover::before {
  opacity: 1;
  /* Make the background visible */
  transform: scale(1);
  /* Bring the background to original size */
}

.digitalworker .swiper {
  width: 100%;
  height: 100%;
}

.digitalworker .swiper-slide {
  text-align: center !important;
  ;
  font-size: 18px !important;
  ;
  background: transparent !important;
  ;

  /* Center slide text vertically */
  display: block !important;
  ;
}

.digitalworker .swiper-slide img {
  display: block !important;
  ;
  width: 25% !important;
  ;
  height: 100% !important;
  ;
  object-fit: cover !important;
  ;
  background-color: #d7cecc !important;
  ;
  border-radius: 15px !important;
  ;
}

.digital-head-div {
  text-align: center;
  /* background-color: #004f98; */
  padding-top: 50px;
  color: #fff;

  h1 {
    font-size: 3em;
    margin: 0;
    font-weight: 600;
    line-height: 1.3;
    letter-spacing: -2;

  }

  p {
    opacity: .5;
    margin-bottom: 0;
    line-height: 1.5;
  }

  /* button {
    background-color: #000;
    color: #fff;
    padding: 12px 24px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: 20px;
  } */
}

.digital-main {
  background-color: #004f98;
  color: #ffff;
}

.digitalworker .swiper-img-div {
  background: #004f98;
  display: flex;
  justify-content: center;
  /* padding-bottom: 100px; */
}

.digitalworker .swiper-slide img {
  /* filter: blur(10px); */
  width: 90% !important;
}

.digitalworker .swiper-slide.swiper-slide-active img {
  filter: blur(0);
  border-radius: 15px;
}

.digitalworker .swiper-head-main {
  text-align: left;
  padding-left: 25px;
  padding-bottom: 80px;

  h1 {
    letter-spacing: -.02em;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1;
  }

  p {

    font-size: 1rem;
    opacity: .5;
    line-height: 1.5;
  }

}

.digitalworker .swiper-slide.swiper-slide-prev {
  filter: blur(3px);
}

.digitalworker .swiper-slide.swiper-slide-next {
  filter: blur(3px);
}

.video-background {
  height: 500px;
}


.footer {
  background-image: url("./images/12-01.png");
  color: #1d3f72;
  padding: 2rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.footer-contain {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
  gap: 20px;
}

.footer-links-main {
  display: flex;
  gap: 11px;
}

.footer-left,
.footer-center,
.footer-right {
  /* flex: 1; */
  /* margin: 0 1rem; */
}

.footer-logo {
  font-size: 4.5rem;
  /* height: 100px; */
  font-weight: bold;
  background-color: #d7ceccbf;
  box-shadow: rgba(0, 0, 0, 0.87) 0px 3px 8px;
  padding: 40px 10px 40px 10px;
  /* display: flex
; */
  width: 200px;
  border-radius: 10px;
  text-align: center;
}

.footer-workers {
  background-color: #d7ceccbf;
  box-shadow: rgba(0, 0, 0, 0.87) 0px 3px 8px;
  width: 200px;
  padding: 20px 0px 30px 20px;
  border-radius: 10px;
  margin-top: 10px;
}

.footer-workers ul,
.footer-center ul {
  list-style: none;
  padding: 0;
  line-height: 2;
}

.footer-workers h4,
.footer-center h4 {
  margin-bottom: 1rem;
  font-size: 25px;
}

.footer-center {
  background-color: #d7ceccbf;
  box-shadow: rgba(0, 0, 0, 0.87) 0px 3px 8px;
  padding: 10px 10px 10px 20px;
  border-radius: 10px;
  width: 200px;
}



.footer-workers li,
.footer-center li {
  margin: 0.5rem 0;
  font-size: 16px;
}

.footer-links {
  width: 200px;
  background-color: #d7ceccbf;
  box-shadow: rgba(0, 0, 0, 0.87) 0px 3px 8px;
  padding: 20px 10px 10px 10px;
  margin-top: 10px;
  border-radius: 10px;
  line-height: 2;
}

.footer-address h4 {
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-size: 20px;
}

.footer-address2 {
  width: 200px;
  background-color: #d1cece;
  box-shadow: rgba(0, 0, 0, 0.87) 0px 3px 8px;
  padding: 10px;
  margin-top: 10px;
  border-radius: 10px;

  p {
    font-size: 14px;
    line-height: 1.5;
  }
}

.footer-address2 h4 {
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-size: 20px;
}

.footer-logo-main {
  background: #d7ceccbf;
    text-align: center;
    padding: 15px;
    border-radius: 15px; text-align: center;
    width: 200px;
  img{
    height: 100px;
    width: 100px;
  }
}
.footer-quick-links{
  background: #d7ceccbf;
    /* text-align: center; */
    padding: 15px;
    border-radius: 15px; text-align: center;
    width: 200px;
    margin-top: 20px;
}

.footer-carrer {
  background-color: #d7ceccbf;
   box-shadow: rgb(32 30 30 / 12%) 0px 3px 8px;
  padding: 15px;
  border-radius: 10px;
  width: 422px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 20px;
  width: 200px;
}
.footer-company {
  background-color: #d7ceccbf;
   box-shadow: rgb(32 30 30 / 12%) 0px 3px 8px;
  padding: 15px;
  border-radius: 10px;
  width: 422px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 20px;
  width: 200px;
}

.footer-address {
  background-color: #d7ceccbf;
  box-shadow: rgb(32 30 30 / 12%) 0px 3px 8px;
  padding: 5px 10px 5px 20px;
  border-radius: 10px;
  width: 422px;
  margin-top: 20px;

  p {
    font-size: 14px;
  }
}
.social-icon-main{
  display: flex;
  gap: 25px;
  margin-top: 20px;
}
.social-icon {
  background-color: #d7ceccbf;
  padding: 10px 40px 10px 40px;
  /* width: 170px; */
  border-radius: 15px;
  img{
    height: 40px;
    width: 40px;
  }
}
.social-icon2 {
  background-color: #d7ceccbf;
  padding: 10px 20px 10px 20px;
  /* width: 170px; */
  border-radius: 15px;

  p{
    font-size: 14px;
  }
}
.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #444;
  padding-top: 1rem;
}

.footer-links a,
.footer-socials a {
  color: #000;
  margin-right: 1rem;
  text-decoration: none;
}

.footer-socials .social-icon {
  background: #4444445c;
  padding: 0.5rem;
  border-radius: 10%;
  display: inline-block;
  text-align: center;
}

.footer-socials {
  background-color: #d7ceccbf;
  box-shadow: rgba(0, 0, 0, 0.87) 0px 3px 8px;
  padding: 25px 10px 10px 10px;
  width: 200px;
  border-radius: 10px;
  margin-top: 10px;
}

.footer-socials-main {
  display: flex;
  gap: 11px;
}

.reserved {
  background-color: #d7ceccbf;
  box-shadow: rgba(0, 0, 0, 0.87) 0px 3px 8px;
  padding: 10px;
  width: 200px;
  border-radius: 10px;
  margin-top: 10px;

  p {
    font-size: 14px;
  }
}


.logo-box {
  width: 70%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid gray;
  margin: 20px 0;
  opacity: .5;
  cursor: pointer;
}

.logo-box:hover {
  opacity: 1;
}

.box-section {
  background-color: #00222F;
  color: #FFFFFF;
  padding: 50px 20px;
  text-align: center;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}
.tags-slider, .tags-slider2, .tags-slider3 {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  display: flex;
  gap: 40px;
}

/* Add a gradient mask effect */
.tags-slider::before,
.tags-slider::after,
.tags-slider2::before,
.tags-slider2::after,
.tags-slider3::before,
.tags-slider3::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50px; /* Width of the gradient blur area */
  z-index: 1;
  pointer-events: none;
}

.tags-slider::before,
.tags-slider2::before,
.tags-slider3::before {
  left: 0;
  background: linear-gradient(to right, rgba(0, 34, 47, 1), rgba(0, 34, 47, 0)); /* Left-side gradient */
}

.tags-slider::after,
.tags-slider2::after,
.tags-slider3::after {
  right: 0;
  background: linear-gradient(to left, rgba(0, 34, 47, 1), rgba(0, 34, 47, 0)); /* Right-side gradient */
}

.tags-container,
.tags-container2,
.tags-container3 {
  display: inline-block;
  white-space: nowrap;
}

.tags-container {
  animation: scrollTags 70s linear infinite;
}

.tags-container2 {
  animation: scrollTagsleft 70s linear infinite;
}

.tags-container3 {
  animation: scrollTags 70s linear infinite;
}

.tag {
  background-color: #005566;
  color: #FFFFFF;
  display: inline-block;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 20px;
  font-size: 14px;
}

/* Animation for sliding */
@keyframes scrollTags {
  0% {
    transform: translateX(0); /* Start position */
  }
  100% {
    transform: translateX(-100%); /* Move completely off-screen */
  }
}

@keyframes scrollTagsleft {
  0% {
    transform: translateX(-100%); /* Start position */
  }
  100% {
    transform: translateX(0); /* Move completely off-screen */
  }
}

.box-container {
  max-width: 1000px;
  margin: 0 auto;
}

.logo-box img {
  width: 100%;
}

.logoSlider .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
  padding-top: 30px !important;
}
.swiper-pagination{
  display: none
}

.logoSlider .swiper-slide.swiper-slide {
  display: flex !important;
  justify-content: center !important;
  opacity: 0.6 !important;
  padding-bottom: 50px;
}

.logoSlider .swiper-slide.swiper-slide-active {
  transition: 0.3s ease-in !important;
  opacity: 1 !important;
  padding-bottom: 50px;
}

.logos-slider {
  padding: 45px;
  background-color: #fff;
}

@media only screen and (max-width: 1920px) {
  /* .landing__header {
    top: 10%;
  } */

}

@media only screen and (max-height: 1040px) {
  /* .landing__header {
    top: 5%;
  } */
}

@media only screen and (max-height: 800px) {
  /* .landing__header {
    top: 9%;
  } */
}

@media only screen and (max-width: 1300px) {
  .landing__title-left {
    font-size: 30px;
  }

  .landing__title-right {
    font-size: 30px;
  }

  .landing__title-center {
    font-size: 5rem;
  }

}

@media only screen and (max-width: 1080px) {
  .landing__title-left {
    font-size: 25px;
  }

  .landing__title-right {
    font-size: 25px;
  }

  .landing__title-center {
    font-size: 4rem;
  }
}

@media only screen and (max-width: 992px) {
  .landing-title-head-sec {
    display: block;
    padding-top: 15%;
    /* padding-left: 10%; */
  }
  .landing__subtitle{
    margin: 30px 0;
  }

  .landing__title-center {
    margin-bottom: 40px;
    margin-top: 40px;
  }

  .footer-address {
    width: 350px;
  }

}

@media only screen and (max-width: 768px) {
  .landing__top p {
    font-size: 16px;
  }

  /* .landing__header {
    top: 5%;
  } */
  .landing-title-head-sec{
    padding-top: 30%;
  }
}

@media only screen and (max-width: 600px) {

  /* .landing__header {
    top: 4%;
  } */

  .digital-head-div h1 {
    font-size: 2em;
  }

  .digital-head-div p {
    font-size: 14px;
  }

  .digitalworker .swiper-head-main {

    h1 {
      font-size: 18px;
    }

    p {
      font-size: 14px;
    }
  }

  .footer-address {
    width: 300px
  }

  .footer-contain {
    gap: 20px;
    justify-content: center;
  }

  .landing__top {
    padding: 0px 14px;

    p {
      font-size: 12px;
    }
  }

  .logos-slider {
    padding: 0px;
  }
}

@media only screen and (max-width: 400px) {
  /* .landing__header {
    top: 9%;
  } */

  .landing-title-head-sec {
    padding-top: 20%;
  }
}